<template>

    <div class="w-full max-h-screen h-full  py-4 pb-20 flex flex-col justify-center items-center"
        style="background:#F4F6F1">

        <div v-if="studentJSON" class="w-11/12 h-full flex justify-between ">
            <!-- 左侧列表 -->
            <div :class="['w-1/4 h-full bg-white  rounded-xl flex flex-col p-2  items-center']">

                <div class="flex flex-col w-full pl-5 mt-3  rounded-lg border-gray-200 border-dashed">
                    <div class="mt-3">姓名:{{ studentJSON.name ? studentJSON.name : studentJSON.nickname }}</div>
                    <div v-if="studentJSON.candidateNumber" class="mt-3">性别:{{ studentJSON.gender == 0 ? '男' : '女' }}
                    </div>
                    <div v-if="studentJSON.candidateNumber" class="mt-3">考号:{{ studentJSON.candidateNumber }}</div>
                    <!-- <div  v-if="studentJSON.candidateNumber" class="mt-3">座号:A08</div> -->
                </div>
                <div class="my-2 bg-gray-50 w-full h-1"></div>

                <!-- 音量-->
                <div class="flex flex-row justify-start p-2 items-center mt-6 w-full max-w-xs">
                    <VolumeUpIcon class=" h-8 w-8 text-gyjdGreen-500 mr-2" aria-hidden="true" />
                    <input type="range" max="100" v-model="volume" @input="volumeChange" class="range ">

                </div>

            </div>

            <!-- 右侧考试信息 -->
            <div
                class="w-3/4 h-full rounded-xl ml-1 flex  justify-center items-center bg-white scrollbar scrollbar-thin scrollbar-thumb-gyjdGreen-500 ">
                <div class="w-full h-full flex flex-col  items-center ">

                    <div class="w-full p-4 pl-10 flex flex-row items-center ">

                        <div class="text-lg">{{ examTraining.category === 1 ? '朗读短文' : '话题简述' }} </div>

                    </div>

                    <div class="my-2 bg-gray-50 w-full h-1"></div>
                    <!-- 题型提示-->
                    <div class="mt-20 px-20 py-2  " style="background: rgba(255,231,193,0.39);border-radius: 4rpx;">
                        {{ isPlaying ? '正在播放音频' : '音频暂停播放' }}
                    </div>

                    <div class="mt-10  text-lg" style="color:#11152B">{{ examTraining.gradeStr }}.{{ examTraining.title
                        }}
                    </div>
                    <div class="w-full max-w-xl mt-10  mx-auto bg-white rounded-3xl  p-4 flex flex-col space-y-4"
                        style="background: rgba(255,255,255,0.39);box-shadow: 0rpx 1rpx 4rpx rgba(141,141,141);border: 1px solid #66CC83;">
                        <!-- 音频控制区 -->
                        <div class="flex items-center space-x-4">
                            <!-- 播放/暂停按钮 -->
                            <button @click="togglePlay" class="focus:outline-none">
                                <img :src="isPlaying ? 'https://file.nj.hlingsoft.com/INzygwEmljx1FxmELalJzBfrOYhmGYdr/%E7%BB%84%2039751.png' : 'https://file.nj.hlingsoft.com/Uf2rhWTyPJio3LhqGIQ3VyB2r3EUsQ4p/%E7%BB%84%203975.png'"
                                    alt="播放/暂停" class="w-10 h-10" />
                            </button>

                            <!-- 当前播放时间 -->
                            <div class="text-gray-600 w-16 text-center">{{ currentTimeStr }}</div>

                            <!-- 进度条 -->
                            <div class="flex-1">
                                <progress class="progress progress-success w-full h-2" :value="currentTime"
                                    :max="duration"></progress>
                            </div>

                            <!-- 音频总时长 -->
                            <div class="text-gray-600 w-16 text-center">{{ durationStr }}</div>
                        </div>

                        <!-- 倍速选择 -->
                        <div class="flex items-center justify-center space-x-4">
                            <span class="text-gray-600">倍速播放：</span>
                            <div class="flex space-x-4">
                                <label v-for="rate in playbackRates" :key="rate"
                                    class="label cursor-pointer flex items-center">
                                    <input type="radio" name="speed" :value="rate" v-model="playbackRate"
                                        @change="changeSpeed" class="radio radio-success" />
                                    <span class="ml-2 text-gray-600">{{ rate }}x</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="mt-10 max-w-2xl px-10 py-5" v-if="examTraining.category === 2"
                        style="background-color: #FCFAFD;border-radius: 3rpx; font-size: 14px; ">
                        <div style="color:#4D4D4D">{{ examTraining.content_en }}</div>
                        <div class="mt-10" style="color:#11152B">{{ examTraining.content_cn }}</div>

                    </div>
                </div>

            </div>

        </div>

    </div>

</template>

<script>
import { reactive, toRefs, onMounted, ref, watchEffect, markRaw, watch, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import AV from 'leancloud-storage'
import { Howl, Howler } from 'howler'
import DayJS from 'dayjs'
import Util from '@/utils/util.js'
import router from '@/router'
import { VolumeUpIcon } from '@heroicons/vue/solid'
export default {
    name: 'DoExam',
    props: {},
    components: {
        VolumeUpIcon
    },
    setup () {
        const store = useStore()
        const route = useRoute()
        console.log(route.params)
        if (!route.params.examTrainingId) {
            router.push({ name: 'ExamTraining' })
        }

        const state = reactive({
            volume: 100,
            isPlaying: false,
            playbackRate: 1.0, // 默认播放速度
            currentTime: 0, // 当前播放时间（秒）
            currentTimeStr: '0:00',
            duration: 0, // 音频总时长（秒）
            durationStr: '0:00',
            playbackRates: [0.75, 1, 1.5, 2], // 可选倍速
            sound: null, // Howler 实例
            timer: null, // 进度更新定时器
            studentJSON: {},
            examTraining: {}
        })
        onBeforeUnmount(() => {
            state.sound.stop()
        })
        onMounted(async () => {
            store.state.studentJSON = Util._jsonify(store.state.student)
            state.studentJSON = store.state.studentJSON
            const examTrainingId = route.params.examTrainingId
            state.examTraining = Util._jsonify(await new AV.Query('GYJDExamTraining').get(examTrainingId))
            console.log(state.examTraining.category)
            localStorage.setItem('examTrainingCategory', state.examTraining.category + '')
            initAudio()
        })

        // 初始化音频
        function initAudio () {
            state.sound = new Howl({
                src: [state.examTraining.url], // 替换为真实音频路径
                html5: true,
                onload: () => {
                    setTimeout(function () {
                        state.duration = Math.floor(state.sound.duration())
                        console.log(state.duration)
                        state.durationStr = formatTime(state.duration)
                        console.log(state.durationStr)
                        togglePlay()
                    }, 1000)
                },
                onend: () => {
                    state.isPlaying = false
                    clearInterval(state.timer)
                    state.currentTime = 0
                    state.currentTimeStr = '0:00'
                }
            })
        }

        // 播放/暂停切换
        function togglePlay () {
            if (state.isPlaying) {
                state.sound.pause()
                // state.isPlaying = false
                clearInterval(this.timer)
            } else {
                state.sound.play()
                // state.isPlaying = true
                startProgress()
            }
            state.isPlaying = !state.isPlaying
        }

        // 开始更新进度
        function startProgress () {
            state.timer = setInterval(() => {
                state.currentTime = Math.floor(state.sound.seek())
                state.currentTimeStr = formatTime(state.currentTime)
            }, 500) // 每 500ms 更新一次
        }

        // 格式化时间（秒 -> mm:ss）
        function formatTime (seconds) {
            const min = Math.floor(seconds / 60)
            const sec = Math.floor(seconds % 60)
            return `${min}:${sec < 10 ? '0' + sec : sec}`
        }

        // 更改播放速度
        function changeSpeed () {
            state.sound.rate(state.playbackRate)
        }
        const volumeChange = async () => {
            // console.log(state.volume)
            Howler.volume(state.volume / 100)
        }
        return {
            volumeChange,
            togglePlay,
            startProgress,
            formatTime,
            changeSpeed,
            ...toRefs(state)
        }
    }
}
</script>
<style scoped>
  /* 自定义样式 */
.progress-success::-webkit-progress-value {
  background-color: #00D48E; /* 进度条的绿色 */
}
.radio-success:checked {
  border-color: #00D48E;
  background-color: #00D48E;
}
  </style>
