<template>
  <Popover class="flex flex-row bg-gyjdGreen-500">
    <div class="w-1/2 px-10 pl-20 ">

      <div class="flex justify-between items-center  border-gray-100 py-6 space-x-10 ">

        <div @click="toHome" href="#" :class="[
            routerName == 'Home' || routerName == '' ? 'text-white font-black text-base' : 'text-white',
            ' font-thin text-sm  cursor-pointer ',
]">
          模拟考试
        </div>
        <div @click="toSimulationExercise" :class="[
          routerName == 'SimulationExercise' ? 'text-white font-black text-base' : 'text-white',
          'font-thin  text-white   cursor-pointer',
]">
          模拟练习
        </div>

        <div @click="toRandomTest" :class="[
          routerName == 'RandomTest' ? 'text-white font-black text-base' : 'text-white',
          'font-thin   text-white  cursor-pointer ',
]">
          随机组卷
        </div>
        <div @click="toExamTraining" :class="[
          routerName == 'ExamTraining' ? 'text-white font-black text-base' : 'text-white',
          'font-thin   text-white  cursor-pointer ',
        ]">
          考改训练
        </div>

        <div @click="toLearningData" :class="[
          routerName == 'LearningData' ? 'text-white font-black text-base' : 'text-white',
          'font-thin   text-white  cursor-pointer ',
]">
          学习数据
        </div>

        <div @click="toPersonalCenter" href="#" :class="[
            routerName == 'Student' ? 'text-white text-base ' : 'text-white',
  'font-thin  text-white hover:text-graycursor-pointer cursor-pointer',
]">
          我的
        </div>

        <!-- <button
          @click="logOut"
          type="button"
          class="flex flex-row bg-white rounded-full p-1 text-gray-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          <span class="mr-2">登出</span>
          <LogoutIcon class="h-6 w-6" aria-hidden="true" />
        </button> -->
      </div>
    </div>
    <div class="w-1/2 flex flex-row items-center justify-end pr-40">

      <div class="flex flex-row items-center">
        <!-- <div class="text-white mr-10">     测试版本号V1.1</div> -->
        <img src="@/assets/logo.png" class="w-8 h-8 rounded">
        <span class="ml-2 text-white">指引解读在线学习平台</span>
      </div>

      <!-- <div
            v-if="studentJSON && studentJSON.avatar"
            class="flex items-center pr-2 ml-10"
          >
            <div class="avatar">
              <div class="w-8 h-8 mask mask-squircle">
                <img :src="studentJSON.avatar" />
              </div>
            </div>
            <span class="ml-3 text-white">{{
              studentJSON.name ? studentJSON.name : studentJSON.nickname
            }}</span>
          </div>
          <div v-else class="cursor-wait w-full"></div>
    -->

    </div>

  </Popover>

  <!-- <div class="alert">
  <div class="flex-1">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#2196f3" class="w-6 h-6 mx-2">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
    <label>建议使用Chrome浏览器</label>
  </div>
</div> -->
</template>

<script>
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel
} from '@headlessui/vue'
import {
  BookmarkAltIcon,
  LogoutIcon,
  SupportIcon,
  BellIcon,
  ArrowCircleLeftIcon
} from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'

import {
  defineComponent,
  watch,
  reactive,
  toRefs,
  onMounted,
  watchEffect
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import DayJS from 'dayjs'
import Confirm from '@/components/confirm.js'

const study = [
  {
    name: '课程',
    description: '课程列表',
    href: '#',
    routerName: 'Course',
    icon: SupportIcon
  },
  {
    name: '训练',
    description: '训练列表',
    href: '#',
    routerName: 'Training',
    icon: BookmarkAltIcon
  }
]

export default defineComponent({
  components: {
    LogoutIcon,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChevronDownIcon,
    BellIcon
  },
  name: 'global-header',
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const state = reactive({
      studentJSON: {},
      routerName: 'Home'
    })

    onMounted(async () => {
      state.routerName = route.name
      const interval = setInterval(function () {
        console.log('学生信息', store.state.studentJSON)
        if (store.state.studentJSON) {
          state.studentJSON = store.state.studentJSON
          interval && clearInterval(interval)
        }
      }, 1000)
    })

    watch(
      () => route.name,
      () => {
        console.log(route.name)
        state.routerName = route.name
      }
    )

    const logOut = async () => {
      Confirm({
        content: '确定登出吗?',
        doneAndNext: async () => {
          AV.User.logOut()
          localStorage.clear()

          location.reload()
        }
      })
    }

    const toHome = async () => {
      router.push({ name: 'Home', force: true })
    }
    const toSimulationExercise = async () => {
      router.push({ name: 'SimulationExercise', force: true })
    }
    const toRandomTest = async () => {
      router.push({ name: 'RandomTest', force: true })
    }
    const toLearningData = async () => {
      router.push({ name: 'LearningData', force: true })
    }
    const toPersonalCenter = async () => {
      router.push({ name: 'Student', force: true })
    }
    const toRouter = async (routeName) => {
      router.push({ name: routeName, force: true })
    }

    const toExamTraining = async () => {
      router.push({ name: 'ExamTraining', force: true })
    }
    return {
      toHome,
      toSimulationExercise,
      toRandomTest,
      toLearningData,
      toPersonalCenter,
      toExamTraining,
      study,
      logOut,
      route,
      router,
      toRouter,

      ...toRefs(state)
    }
  }
})
</script>
