<template>

    <!-- <HLGradeList :series="series" :operationType="operationType" @toDoAnswer="toDoAnswer" /> -->

    <div style="background:#F4F6F1"
        class="w-full min-h-screen h-screen py-4  flex flex-row justify-center items-center ">
        <!-- 列表页面 -->
        <div class="w-11/12 h-full mx-auto bg-white  overflow-auto  p-10  rounded-2xl">
            <div class="flex flex-row items-center gap-x-10">

                <!-- Tab 1 -->
                <div :style="currentTab === 0 ? activeStyle : inactiveStyle" class=" cursor-pointer" @click="setTab(0)">
                    朗读短文
                </div>

                <!-- Tab 2 -->
                <div :style="currentTab === 1 ? activeStyle : inactiveStyle" class=" cursor-pointer" @click="setTab(1)">
                    话题简述
                </div>

            </div>

            <ul class="w-5/6 mx-auto pb-10 mt-10 ">
                <li @click="startTest(training.objectId)" v-for="training in allExamTraining" :key="training.objectId"
                    style="box-shadow: 0px 3px 20px rgba(159, 159, 159, 0.16);"
                    class="cursor-pointer flex flex-row  justify-between items-center h-20 w-50 rounded-lg pl-6 pr-10  mt-4">
                    <div class="flex flex-row justify-center items-center">
                        <img src="@/assets/images/test_paper.png" alt="" class="w-5 h-6" />
                        <div class="ml-4">{{ training.gradeStr }}.{{ training.title }}</div>
                    </div>
                    <div class="bg-gyjdGreen-600 text-white rounded-lg pt-2 pb-2 pl-6 pr-6 ">
                        <!-- operationTypeTextArray: ['专项训练-听力', '专项训练-口语', '模拟训练-考试', '模拟训练-练习', '随机组卷-考试', '随机组卷-练习'] -->
                        练习
                    </div>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import HLGradeList from '@/views/home/gradeList.vue'
import DayJS from 'dayjs'
import Util from '@/utils/util.js'
import router from '@/router'

export default {
    components: {

    },
    setup () {
        const store = useStore()
        const state = reactive({
            series: ref(3),
            operationType: ref(3),
            currentTab: 0,
            allExamTraining: [],
            activeStyle: {
                borderBottom: '4px solid #53B862',
                color: '#53B862',
                fontWeight: 'bold'
            },
            inactiveStyle: {
                color: '#BCBCBC'
            }
        })
        onMounted(async () => {
            const examTraniningCategory = localStorage.getItem('examTrainingCategory') || '1'
            if (examTraniningCategory === '1') {
                state.currentTab = 0
                state.allExamTraining = await getAllReadingPassages()
            } else {
                state.currentTab = 1
                state.allExamTraining = await getAllTopicSummary()
            }
            localStorage.removeItem('examTrainingCategory')
        })

        const getAllReadingPassages = async () => {
            const grades = await new AV.Query('GYJDExamTraining')
                .equalTo('category', 1)
                .descending('sort')
                .find()

            return Util._jsonify(grades)
        }

        const getAllTopicSummary = async () => {
            const grades = await new AV.Query('GYJDExamTraining')
                .equalTo('category', 2)
                .descending('sort')
                .find()

            return Util._jsonify(grades)
        }
        const setTab = async (tabIndex) => {
            state.currentTab = tabIndex
            if (tabIndex === 1) {
                state.allExamTraining = await getAllTopicSummary()
            } else {
                state.allExamTraining = await getAllReadingPassages()
            }
        }
        const startTest = (examTrainingId) => {
            console.log(examTrainingId)
            router.push({ name: 'DoExam', params: { examTrainingId: examTrainingId } })
        }
        return {

            ...toRefs(state),
            setTab,
            startTest
        }
    }
}
</script>
<style scoped>
.activeStyle{
    border-bottom: 2px solid #53B862;
    color: "#53B862",
}
.inactiveStyle{
    color: "#BCBCBC",
}

</style>
